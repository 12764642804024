import './scss/index.scss';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { useLocalePrefix } from '../../lang';
import { useIntl } from "react-intl";

import {
    useTrackClick,
    Svg,
    DripExplanation,
    TagComponent,
    CircleIndexComponent,
    getTitle,
    getType,
} from '../../common';

import { calculateTaskProgress } from '../../common/utils/';
import { ProgramsSubjectIconComponent } from '../../program/ProgramsSubjectIcon.component';
import { EmbedContext } from '../../../context/embed';
import { Loader } from '../../Loader';

export const Thread = ({
    taskId,
    event,
    stepId,
    taskResult,
    result,
    inProgress,
    inProgressNextStep,
    workspace,
    user,
    loadingData,
    handleMenuOpen,
    showNotificationTag,
    tagsRenew,
}) => {
    const { isEmbed } = useContext(EmbedContext) || {};
    const EMBED = isEmbed || '';
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const trackClick = useTrackClick();
    const panelClass = classnames('connected-panel', {
        'disable-el': inProgressNextStep || inProgress,
    });

    const canCreateEvent = (event?.creator?.id === user?.id);
    if (!taskResult) {
        return <Loader />;
    }
    return (
        <div className="flex-right-column thread-panel">
            <Button
                className="task-panel-btn task-panel-btn-collapse"
                type="text"
                onClick={handleMenuOpen}
            >
                <FontAwesomeIcon icon={faBars} />
            </Button>
            <div className={panelClass}>
                {event && (
                    <div>
                        <small className="text_secondary">
                            {formatMessage({ id: "course_title" })}:
                        </small>
                        <Link
                            className="event-cur-item__title"
                            to={{
                                pathname: `${localePrefix}/${EMBED}online-courses/${event?.alias}-${event?.id}`,
                                state: { workspace },
                            }}
                        >
                            <h3 className="link-secondary">
                                {event?.title?.substring(0, 60)}
                            </h3>
                        </Link>
                    </div>
                )}

                {event?.drip && (
                    <DripExplanation
                        classText="text_secondary"
                        formatMessage={formatMessage}
                        event={event}
                    />
                )}

                {taskResult?.map((el, i) => {
                    if (!el?.isDraft || (el?.isDraft && canCreateEvent)) {
                        const {
                            progressColor,
                            completedCount,
                            allCount,
                        } = calculateTaskProgress(el, event, result);

                        return (
                            <div key={`${el?.id}_${i}`}>
                                <div
                                    className={
                                        el?.id === taskId
                                            ? 'connected-panel__item card-active'
                                            : 'connected-panel__item'
                                    }
                                    style={{
                                        color: el?.tags?.[0]?.subject?.color,
                                        opacity: el?.isDraft ? 0.3 : 1,
                                    }}
                                >
                                    <div
                                        className={`answer-component Answer_container ${
                                            !el?.description ? 'empty' : ''
                                        }`}
                                    >
                                        <CircleIndexComponent
                                            isActive={el?.id === taskId}
                                            check={event?.isSubscribe && el?.stateResult}
                                            icon={
                                                el?.isHidden ||
                                                (!el?.isFree &&
                                                    !event?.isSubscribe &&
                                                    !event?.isDraft &&
                                                    !canCreateEvent) ? (
                                                    <Svg
                                                        name=":icon/admin"
                                                        className="menu-icon"
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            }
                                        />

                                        <Link
                                            to={`${localePrefix}/${EMBED}tutorials/${el?.alias}-${el?.id}`}
                                            className={
                                                el?.id === taskId
                                                    ? 'connected-panel__Link connected-panel__Link_active'
                                                    : 'connected-panel__Link'
                                            }
                                            onClick={() => {
                                                trackClick(
                                                    `click-thread`,
                                                    'nextStep',
                                                    `https://unschooler.me/`
                                                );
                                            }}
                                        >
                                            {el?.title}
                                        </Link>
                                        {el?.isDraft && (
                                            <span className="draft-icon">Draft</span>
                                        )}
                                    </div>

                                    {el?.id === taskId && !!el?.questions?.length && (
                                        <div className="connected-panel__subitem">
                                            {el?.questions?.map((question, i) => (
                                                <div
                                                    key={`question_${el?.id}-${question.id}-${i}`}
                                                    className="connected-panel__subitem__item"
                                                >
                                                    <CircleIndexComponent
                                                        isActive={question?.id == stepId}
                                                        check={
                                                            event?.isSubscribe &&
                                                            question?.stateResult
                                                        }
                                                        icon={
                                                            question.isHidden ? (
                                                                <Svg
                                                                    name=":icon/admin"
                                                                    className="menu-icon"
                                                                />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />

                                                    <Link
                                                        to={`${localePrefix}/${EMBED}tutorials/${el?.alias}-${el?.id}?step=${question?.id}`}
                                                        onClick={() => {
                                                            trackClick(
                                                                `click-thread`,
                                                                'nextStep',
                                                                `https://unschooler.me/`
                                                            );
                                                        }}
                                                        className={
                                                            question?.id == stepId
                                                                ? 'connected-panel__Link  connected-panel__Link_active'
                                                                : 'connected-panel__Link  '
                                                        }
                                                    >
                                                        <span className="feature">
                                                            {event &&
                                                                getType(
                                                                    question?.type,
                                                                    question
                                                                )}
                                                            {getTitle(
                                                                (question?.question &&
                                                                    question?.question?.replace(
                                                                        /#/g,
                                                                        ''
                                                                    )) ||
                                                                    question?.title?.replace(
                                                                        /#/g,
                                                                        ''
                                                                    )
                                                            )}
                                                        </span>
                                                    </Link>
                                                </div>
                                            ))}

                                            {event?.badge && el.tags?.length > 0 && (
                                                <div
                                                    className="wrapper_progress_achevement_thread"
                                                    onClick={() => {
                                                        showNotificationTag(
                                                            el.tags,
                                                            allCount === completedCount ? 
                                                              formatMessage({ id: 'notif_achieve_completed' }) :
                                                              formatMessage({ id: 'notif_achieve_theme' }, { count: el.questions.length }),
                                                            allCount === completedCount ?
                                                              formatMessage({ id: 'congrats_on_completion' }) :
                                                              formatMessage({ id: 'congrats' }),
                                                            result,
                                                            allCount === completedCount
                                                        );
                                                    }}
                                                >
                                                    <ProgramsSubjectIconComponent
                                                        width={30}
                                                        achievement={el.tags[0]}
                                                        hideIcon={true}
                                                        colorfull={completedCount === allCount}
                                                    />
                                                    <div className="wrapper_progress_thread">
                                                        <div
                                                            key={`skill-tag-${el.tags[0]?.id}`}
                                                            className="skills_tag skill_progress"
                                                        >
                                                            <TagComponent
                                                                tag={{
                                                                    ...el.tags[0],
                                                                    title: el.tags.map(tag => tag.title).join(', ').substring(0, 34),
                                                                    color: progressColor,
                                                                }}
                                                                countResults={{
                                                                    all: allCount,
                                                                    done: completedCount,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}

                {loadingData && <Loader />}
            </div>
        </div>
    );
};
