import React from 'react';
import { useIntl } from 'react-intl';
import { ProgramsSubjectIconComponent } from '../program/ProgramsSubjectIcon.component';

export const TaskProgressComponent = ({
    backgroundColor,
    progressColor,
    progressPercentage,
    completedCount,
    allCount,
    task,
    tagsRenew,
    specIndex,
    event,
    question
}) => {
    const { formatMessage } = useIntl();
    const getProgressText = () => {
        if (!question) return formatMessage({ id: 'read_and_watch_video' });
        
        switch (question.type) {
            case 'SINGLE_SELECT':
            case 'MULTI_SELECT':
                return formatMessage({ id: 'solve_the_quiz_to_complete' });
            case 'TEXT':
                return formatMessage({ id: 'send_task_solution_to_complete' });
            default:
                return formatMessage({ id: 'read_and_watch_video' });
        }
    };

    return (
        <div className="task-progress-component flex-grow_1">
            <div className='wrapper_progress' style={{ backgroundColor }}>
                <small className='progress_label'>
                    {getProgressText()}: {completedCount}/{allCount} —
                        <strong> {task?.tags?.map(tag => tag.title).join(', ')}</strong>
                </small>
                <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}>
                    <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}></div>
                </div>
            </div>
            {event?.badge && tagsRenew?.length > 0 &&
                <div className=' wrapper_progress_achevement_progress'>
                    <ProgramsSubjectIconComponent
                        source={tagsRenew[0].src}
                        width={50}
                        achievement={{ ...tagsRenew[0], color: progressColor }}
                        specIndex={specIndex}
                        event={event}
                        colorfull={completedCount === allCount}
                    />
                </div>
            }
        </div>
    );
};
