import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useContext } from 'react';

import { QuestionsFormContainer, Thread, TaskPlaceholderComponent } from '../task';
import { useQueryParams } from '../common';

import { FirebaseContext } from '../../context/firebase/firebaseContext';
import { calculateTaskProgress } from '../common/utils/calculateTaskProgress';
import { AchievementPreviewComponent } from '../badges/';
import { Modal } from 'antd';

export const TaskCourseArticleComponent = (props) => {
    const {
        task,
        user,
        event,
        handleSayMeAnswers,
        result,
        highlightedRequest,
        isJoinRequired,
        userIsAllowGenerateContent,
        ...other
    } = props;
    const { isSingleMode, isSingleLesson } = useQueryParams();
    const { formatMessage } = useIntl();
    const { localePrefix } = useContext(FirebaseContext);

    const [menuOpen, setMenuOpen] = useState(() => {
        const storedMenuOpen = window.localStorage.getItem('menuOpen');
        return storedMenuOpen !== null ? JSON.parse(storedMenuOpen) : true;
    });

    const handleMenuOpen = () => {
        setMenuOpen(prev => {
            const newState = !prev;
            window.localStorage.setItem('menuOpen', JSON.stringify(newState));
            return newState;
        });
    };
    const showNotificationTag = (tags, triggerMessage, triggerDescription, cloneResult, share) => {
        let progress = calculateTaskProgress(task, event, cloneResult);
        const modal = Modal.info({
            title: null,
            centered: true,
            closable: true,
            maskClosable: true,
            icon: null,
            width: "auto",
            style: { maxWidth: '800px' },
            okButtonProps: { style: { display: 'none' } },
            content: (
                <AchievementPreviewComponent
                    achievements={tags.map(tag => ({
                        ...tag,
                        counter: {
                            all: progress.allCount,
                            done: progress.completedCount
                        }
                    }))}
                    share={share}
                    triggerMessage={triggerMessage}
                    triggerDescription={triggerDescription}
                    onClose={() => modal.destroy()}

                    formatMessage={formatMessage}
                    localePrefix={localePrefix}
                />
            )
        });
    };

    return (
        <div className="task-article-component">
            {(menuOpen && !isSingleMode && !isSingleLesson) &&
                <Thread
                    event={event}
                    task={task}
                    taskId={task?.id}
                    result={result}
                    user={user}
                    showNotificationTag={showNotificationTag}
                    handleMenuOpen={handleMenuOpen}
                    {...other}
                />
            }
            <div className='task-article-content'>
                {isJoinRequired && (
                    <TaskPlaceholderComponent
                        task={task}
                        event={event}
                        user={user}
                        type={isJoinRequired}
                        {...other}
                    />
                )}

                {!isJoinRequired && (
                    <section className="article">
                        <QuestionsFormContainer
                            {...other}
                            id={task?.id}
                            task={task}
                            user={user}
                            event={event}
                            sayMeAnswers={handleSayMeAnswers}
                            result={result}
                            highlightedRequest={highlightedRequest}
                            singleMode={isSingleMode}
                            singleLesson={isSingleLesson}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                            setMenuOpen={handleMenuOpen}
                            menuOpen={menuOpen}
                            showNotificationTag={showNotificationTag}
                        />
                    </section>
                )}
            </div>
        </div>
    );
}
